import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Study App Template | Study App UI Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/study-mobile-app/"
    metaDescription="Bring your study app design to life with StudyPal, Uizard's study app UI design template for mobile devices. Sign up to Uizard today."
    description="
    h2:StudyPal, a study app UI design template for mobile devices
    <br/>
    Bring your study app design to life with StudyPal, our study app UI design template for mobile. Whether you are an experienced designer or new to the world of UX, Uizard <a:https://uizard.io/templates/>UI templates</a> are the perfect launching pad for designing your own app.
    <br/>
    h3:App design just got a whole lot easier
    <br/>
    Transform the way you design with our stunning app UI templates. Simply sign up to Uizard Pro, open the editor, and select the StudyPal app template to bring your vision to life instantly. All our design templates are fully customizable; add new screens, edit components, import new wireframes, <a:https://uizard.io/templates/mobile-app-templates/>app design</a> has never been so easy.
    <br/>
    h3:Work rapidly with your team and bring your idea to life in minutes
    <br/>
    With Uizard, collaboration is easy. Share a link to your project with team members or invite internal and external stakeholders to collaborate on your design via email, you can even share your project with prospective customers and gather feedback through Uizard's easy-to-use commenting system.
    "
    pages={[
      "A sleek and clean user login flow with all the core CTAs your design could need",
      "Category screens breaking down individual study app entries into clear content blocks",
      "Class overview screen with descriptive summary text and listicles",
      "Homework tracker screen with intuitive CTA placement and user flow",
    ]}
    projectCode="YyjKm1Wqb8TLyzwe1B58"
    img1={data.image1.childImageSharp}
    img1alt="Study mobile app summary"
    img2={data.image2.childImageSharp}
    img2alt="Study mobile app individual study app entry"
    img3={data.image3.childImageSharp}
    img3alt="Class detail screen"
    img4={data.image4.childImageSharp}
    img4alt="Class overview screen "
    img5={data.image5.childImageSharp}
    img5alt="Homework tracker screens"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/study-mobile-app/study-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/study-mobile-app/study-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/study-mobile-app/study-app-class-detail.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/study-mobile-app/study-app-classes.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/study-mobile-app/study-app-homework.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
